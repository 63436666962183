<template>
  <div>
    <b-form>
      <b-form-group label-for="revenue_plan">
        <template v-slot:label>
          Kế hoạch thuê <span class="text-danger">*</span>
        </template>
        <v-select
          id="admissionFormId"
          v-model="targetRevenueSchedule.admission_revenue_plan_id"
          :options="dataListRevenuePlan"
          :reduce="option => option.value"
          placeholder="Kế hoạch thu"
        />
      </b-form-group>

      <b-form-group label-for="start_at">
        <template v-slot:label>
          Thời gian bắt đầu <span class="text-danger">*</span>
        </template>

        <flat-pickr
          v-model="targetRevenueSchedule.start_at"
          :config="config"
          class="form-control"
          placeholder="Thời gian bắt đầu"
          name="start_at"
        />
      </b-form-group>

      <b-form-group label-for="start_at">
        <template v-slot:label>
          Thời gian kết thúc <span class="text-danger">*</span>
        </template>

        <flat-pickr
          v-model="targetRevenueSchedule.end_at"
          :config="config"
          :disabled="!targetRevenueSchedule.start_at"
          class="form-control"
          placeholder="Thời gian kết thúc"
          :min-date="targetRevenueSchedule.start_at"
          name="end_at"
          @on-change="valTime"
        />

      </b-form-group>
    </b-form>
    <div class="w-100 d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1"
        :disabled="invalid"
        @click="onSave('hide')"
      >
          <span class="text-right">
            <feather-icon icon="CheckIcon"/> Lưu lại
          </span>
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        @click="$bvModal.hide('modal-save')"
      >
          <span class="text-right">
            <feather-icon icon="XIcon"/> Hủy
          </span>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import {mapActions, mapGetters} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import {vn} from 'flatpickr/dist/l10n/vn.js'
import vSelect from 'vue-select'
import FlatpickrLanguages from "flatpickr/dist/l10n";
export default {
  name: 'RevenueScheduleSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect
  },
  directives: {
    Ripple,
  },
  props: {
    revenueSchedule: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetRevenueSchedule: {
        admission_revenue_plan_id: null,
        start_at: null,
        end_at: null,
        id: null
      },
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        // minDate: 'today',
      },
      required,
      dataListRevenuePlan: [],
      invalid: false
    }
  },
  computed: {
    ...mapGetters({
      admissionRevenuePlans: 'admissionRevenuePlan/admissionRevenuePlans',

    }),
  },

  async created() {
    if (this.revenueSchedule) {
      this.targetRevenueSchedule = {...this.revenueSchedule}
    }
    await Promise.all([
      this.readAdmissionRevenuePlans({currentPage: 1, itemsPerPage: 1000}),
    ])
    this.dataListRevenuePlan = this.admissionRevenuePlans.map(item => {
      return {
        label: item.admissionRevenueName,
        value: item.id
      }
    })

  },

  methods: {
    ...mapActions({
      readAdmissionRevenuePlans: 'admissionRevenuePlan/readAdmissionRevenuePlans',
      createRevenueSchedule: 'admissionRevenueSchedule/createAdmissionRevenueSchedule',
      updateRevenueSchedule: 'admissionRevenueSchedule/updateAdmissionRevenueSchedule',

    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },

    async onSave(type = null) {
      this.isLoading = true
      try {
        const param = {
          admission_revenue_plan_id: this.targetRevenueSchedule.admission_revenue_plan_id,
          start_at: this.targetRevenueSchedule.start_at,
          end_at: this.targetRevenueSchedule.end_at,
          id: this.targetRevenueSchedule.id,
          status: 1
        }
        const response = await this.createRevenueSchedule(param)
        if (response) {
          const {isSuccessful, message} = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('modal-save')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    valTime(selectedDates, dateStr, instance) {
      if (dateStr !== '') {
        if (dateStr <= this.targetRevenueSchedule.start_at) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thời gian kết thúc phải lớn hơn bắt đầu!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
